/**
 * Context used for the Dashoboard/Home Page.
 * Use this to manage any common data that has to be shared within the dashboard components.
 * Reducer is used to dispatch the actions to update the context values.
 */
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';
import { useAuth } from 'react-oidc-context';
import { getCurrentCycle } from 'services/spoDashboard.service';
import { DASHBOARD_VIEWS, STORAGE_KEYS } from 'utils/enums';
import { produce } from 'immer';
import { useSessionStorage } from 'hooks/useStorage';
import {
  APPROVED_HISTORY_TABLE_ORDER,
  CANCELLED_TABLE_ORDER,
  DEFAULT_DASHBOARD_TABLE_ORDER,
} from 'views/DashboardTable/table-fields';

const initialState = {
  currentView: DASHBOARD_VIEWS.ACTIVE_ORDERS.value,
  currentSubViewTab: null,
  showQuotas: false,
  dataFetchedOn: null,
  currentGetsudoCycle: null,
  // region: {
  //   //hardcoded as we currently supporting only one region. Update the code accordingly once more regions are enabled.
  //   name: 'San Francisco',
  //   code: '120',
  // },
  region: null,
  adminFilters: {},
  tableFilters: {
    REVIEW_REQUIRED: [],
    APPROVED_ORDERS: {
      LIST_VIEW: [],
      HISTORY: [],
    },
    CANCELLED_ORDERS: [],
    EXPIRED_ORDERS: [],
  },
  tableOrder: {
    REVIEW_REQUIRED: [...DEFAULT_DASHBOARD_TABLE_ORDER],
    APPROVED_ORDERS: {
      LIST_VIEW: [...DEFAULT_DASHBOARD_TABLE_ORDER],
      HISTORY: [...APPROVED_HISTORY_TABLE_ORDER],
    },
    CANCELLED_ORDERS: [...CANCELLED_TABLE_ORDER],
    EXPIRED_ORDERS: [...DEFAULT_DASHBOARD_TABLE_ORDER],
  },
};

export const SpoDashboardContext = createContext();

export const SpoDashboardReducer = (state, action) => {
  switch (action.type) {
    case 'CURRENT_VIEW':
      return {
        ...state,
        currentView: action.payload,
      };
    case 'CURRENT_SUB_VIEW_TAB':
      return {
        ...state,
        currentSubViewTab: action.payload,
      };
    case 'UPDATE_TIME':
      return {
        ...state,
        dataFetchedOn: new Date().toUTCString(),
      };
    case 'VIEW_QUOTA':
      return {
        ...state,
        showQuotas: action.payload,
      };
    case 'GETSUDO_CYCLE':
      return {
        ...state,
        currentGetsudoCycle: action.payload,
      };
    case 'REGION':
      return {
        ...state,
        region: action.payload,
      };
    case 'ADMIN_FILTERS':
      return {
        ...state,
        adminFilters: action.payload,
      };
    case 'TABLE_FILTERS':
      return {
        ...state,
        tableFilters: action.payload,
      };
    default:
      return state;
  }
};

const SpoDashboardProvider = ({ children }) => {
  // Get the context value from Storage.
  const [dashboardCtxValue, setDashboardCtxValue] = useSessionStorage(
    STORAGE_KEYS.DASHBOARD_CONTEXT,
    null,
  );
  const [state, dispatch] = useReducer(
    produce(SpoDashboardReducer),
    dashboardCtxValue ? dashboardCtxValue : initialState, // Set the value from storage if available.
  );
  const auth = useAuth();

  useEffect(() => {
    setDashboardCtxValue(state);
  }, [state]);

  const updateCurrentView = (value) => {
    dispatch({
      type: 'CURRENT_VIEW',
      payload: value,
    });
  };

  const updateCurrentSubView = (value) => {
    dispatch({
      type: 'CURRENT_SUB_VIEW_TAB',
      payload: value,
    });
  };

  const updateDataFetchedOn = () => {
    dispatch({
      type: 'UPDATE_TIME',
    });
  };

  const updateShowQuotas = (value) => {
    dispatch({
      type: 'VIEW_QUOTA',
      payload: value,
    });
  };

  const updateGetsudoCycle = (value) => {
    dispatch({
      type: 'GETSUDO_CYCLE',
      payload: value,
    });
  };

  const updateRegion = (value) => {
    dispatch({
      type: 'REGION',
      payload: value,
    });
  };

  const updateAdminFilters = (value) => {
    dispatch({
      type: 'ADMIN_FILTERS',
      payload: value,
    });
  };

  const updateTableFilters = (value) => {
    dispatch({
      type: 'TABLE_FILTERS',
      payload: value,
    });
  };

  const { data } = useQuery({
    queryKey: ['CurrentCycle'],
    queryFn: () => getCurrentCycle(auth?.user?.access_token),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    updateGetsudoCycle(data);
  }, [data]);

  return (
    <SpoDashboardContext.Provider
      value={{
        state,
        updateCurrentView,
        updateDataFetchedOn,
        updateShowQuotas,
        updateGetsudoCycle,
        updateRegion,
        updateAdminFilters,
        updateCurrentSubView,
        updateTableFilters,
      }}
    >
      {children}
    </SpoDashboardContext.Provider>
  );
};

SpoDashboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SpoDashboardProvider;
